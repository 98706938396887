import React from 'react'
import LaunchOffer from 'components/LaunchOffer'
import FreeAndPlans from 'components/FreeAndPlans'
import FlowStepFor from 'components/FlowStepFor'

import Community from 'components/Community'
import Image from 'next/image'

import womanMakingASale from '/public/img/sample/woman_making_a_sale.jpg'

export interface Props {}

const LandingPage: React.FC<Props> = () => {
  return (
    <>
      {/* Launch Offer
      <div className="mb-10 rounded-lg bg-flowStep">
        <div className="max-w-screen-xl px-4 py-6 mx-auto sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <div>
            <h3 className="mt-0 font-extrabold tracking-tight text-flowStep150">
              Launch Offer:
            </h3>
            <h4 className="mt-4 font-semibold text-white">
              Puchase a "Team" plan for up to 25 users for only $10 per user per
              month!*
            </h4>
            <h5 className="inline-flex px-4 py-2 mt-2 mb-0 text-lg font-semibold tracking-wide text-white rounded-lg bg-flowAction">
              Saving up to $400/m
            </h5>
            <h6 className="float-right text-sm font-semibold text-white">
              *Limits apply per{' '}
              <dfn
                className="cursor-help"
                title="Regions include The Americas, EMEA, Asia, Oceania"
              >
                region
              </dfn>
            </h6>
          </div>
          <div className="flex mt-8 lg:flex-shrink-0 lg:mt-0 lg:ml-20">
            <div className="inline-flex rounded-md shadow">
              <a
                href="/pricing"
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-flowStep150 hover:text-white hover:bg-flowStep80"
              >
                View Details!
              </a>
            </div>
          </div>
        </div>
      </div>
      */}

      {/* Welcome */}
      <div className="pb-10 bg-white">
        <main className="max-w-screen-xl px-4 pt-8 mx-auto">
          <h1 className="pb-6 text-flowStep150 lg:text-6xl">
            A Collaborative, API First Framework for Modern Software Development
          </h1>
          <div className="lg:grid lg:grid-cols-12 lg:gap-8 lg:items-end">
            <div className="lg:col-span-6">
              {/*  <div className="text-sm font-semibold tracking-wide uppercase text-flowStep40 sm:text-base lg:text-sm xl:text-base">
                Introducing
              </div>
                  */}
              <h1>
                FlowStep is unique in the way it provides people with the{' '}
                <span className="md:block">
                  <span className="text-flowContext">context to</span>{' '}
                </span>
                <span className="md:block">
                  <span className="text-flowAction">create</span>{' '}
                </span>
                <span className="md:block">
                  <span className="text-flowOutcome">great software</span>
                </span>
              </h1>
            </div>
            <div className="relative mt-12 sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6">
              <div className="relative w-full mx-auto rounded-lg shadow-md lg:max-w-md">
                <Image
                  src={womanMakingASale}
                  alt="Woman making a sale"
                  layout="responsive"
                  width={1350}
                  height={900}
                  placeholder="blur"
                />
              </div>
            </div>
          </div>
        </main>
      </div>

      <LaunchOffer showPricingLink />
      <FreeAndPlans />

      <section className="px-4 mb-8" aria-labelledby="quick-links-title">
        <h2 id="quick-links-title">Explore FlowStep</h2>

        <h3 className="mt-0 leading-tight text-flowStep150">
          Requirements, Documentation, Artifacts and Governance for Modern
          Software
        </h3>
        <p className="pb-4 mt-3 mb-0 text-base leading-snug text-flowStep80 sm:text-xl lg:text-lg xl:text-xl">
          FlowStep promotes{' '}
          <span className="block lg:inline-block">
            <strong className="font-semibold text-flowStep">
              Good Governance
            </strong>{' '}
            philosophies,
          </span>{' '}
          <span className="block lg:inline-block">
            <strong className="font-semibold text-flowStep">API First</strong>{' '}
            principles,
          </span>{' '}
          <span className="block lg:inline-block">
            <strong className="font-semibold text-flowStep">
              Human Centered Design
            </strong>{' '}
            processes and
          </span>{' '}
          <span className="block lg:inline-block">
            <strong className="font-semibold text-flowStep">
              Behaviour Driven Development
            </strong>{' '}
            practices.
          </span>
        </p>

        <FlowStepFor />
      </section>

      <section className="mb-8" aria-labelledby="Community">
        <Community />
      </section>

      {/* Partner Program
      <div className="relative overflow-hidden bg-flowOutcome">
        <div className="max-w-screen-xl mx-auto">
          <div className="relative z-10 pb-8 bg-flowOutcome sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="absolute inset-y-0 right-0 hidden w-48 h-full transform translate-x-1/2 lg:block text-flowOutcome"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
            <div className="relative px-4 pt-6 sm:px-6 lg:px-8"></div>

            <main className="max-w-screen-xl px-4 mx-auto mt-10 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-white">
                  Get Notified <br className="xl:hidden" />
                  <span className="text-flowStep">on 15th February, 2021</span>
                </h1>
                <p className="mt-3 text-base text-flowStep sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  FlowStep is being launched on the 15th February 2021{' '}
                  <strong className="text-white">Register Now</strong> for early
                  access and{' '}
                  <strong className="text-white">big discounts</strong> with our
                  limited launch offer.
                </p>
                <div className="mt-5 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                  <p className="text-base font-medium text-white">
                    Register for access to our limited launch offer.
                  </p>
                  <form action="#" method="POST" className="mt-3 sm:flex">
                    <input
                      aria-label="Email"
                      className="block w-full px-3 py-3 text-base leading-6 transition duration-150 ease-in-out border rounded-md shadow-sm appearance-none placeholder-flowStep050 border-flowStep20 focus:outline-none focus:placeholder-flowStep40 focus:shadow-outline-blue focus:border-blue-300 sm:flex-1"
                      placeholder="Enter your email"
                    />
                    <button
                      type="submit"
                      className="w-full px-6 py-3 mt-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm bg-flowStep hover:bg-flowStep80 focus:outline-none focus:border-flowStep40 focus:shadow-outline-gray active:bg-flowStep80 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                    >
                      Submit
                    </button>
                  </form>
                  <p className="mt-3 text-sm leading-5 text-white">
                    We care about the protection of your data. Read our{' '}
                    <a
                      href="/privacy-policy"
                      className="font-medium underline text-flowStep"
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="object-cover w-full h-56 sm:h-72 md:h-96 lg:w-full lg:h-full"
            src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
            alt=""
          />
        </div>
      </div>

      <TryFree />
      */}
    </>
  )
}

export default LandingPage
