import React from 'react'
import { FaDiscord } from 'react-icons/fa'
import { FaReddit } from 'react-icons/fa'

export interface Props {}

const Community: React.FC<Props> = () => {
  return (
    <>
      <div className="max-w-screen-xl px-4 py-4 mx-auto">
        <div className="pb-6 overflow-hidden rounded-lg shadow-xl bg-flowOutcome lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="px-6 py-10 sm:px-16 lg:pr-0">
            <div className="lg:self-center">
              <h1 className="my-0 leading-none">More Questions?</h1>
              <h2 className="mt-0 text-white">Community and Support</h2>
              <p className="my-4 text-lg leading-6 text-white">
                Check out our Discord and Reddit communities, to get and give
                help, learn about our platform, and find out about our free and
                premium support packages.
              </p>

              <a
                href="https://discord.gg/YZnZ5Krgty"
                target="_blank"
                title="Visit our Discord Community (external link)"
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 transition duration-150 ease-in-out bg-white border border-transparent rounded-md text-flowStep hover:text-flowStep60 focus:outline-none focus:ring"
                rel="noreferrer"
              >
                Visit Discord <FaDiscord title="Discord" className="ml-2" />
              </a>

              <a
                href="https://www.reddit.com/r/flowstep/"
                target="_blank"
                title="Visit the FlowStep Subreddit (external link)"
                className="inline-flex items-center justify-center px-5 py-3 mt-2 ml-0 text-base font-medium leading-6 transition duration-150 ease-in-out bg-white border border-transparent rounded-md md:mt-0 md:ml-2 text-flowStep hover:text-flowStep60 focus:outline-none focus:ring"
                rel="noreferrer"
              >
                Visit Reddit <FaReddit title="Reddit" className="ml-2" />
              </a>
            </div>
          </div>
          {/* Replace this image when we can take a screenshot of one of the communities and they are less embarassing
          <div className="relative -mt-6 pb-3/5 md:pb-1/2">
            <img
              className="absolute inset-0 object-cover object-left-top w-full h-full transform translate-x-6 translate-y-6 rounded-md sm:translate-x-16 lg:translate-y-20"
              src="https://tailwindui.com/img/component-images/full-width-with-sidebar.jpg"
              alt="App screenshot"
            />
          </div>
          */}
        </div>
      </div>
    </>
  )
}

export default Community
