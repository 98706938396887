import Link from 'next/link'
import React from 'react'

export interface Props {}

const FreeAndPlans: React.FC<Props> = () => {
  return (
    <>
      {/* Free and Plans */}

      <div className="mb-16 border bg-flowStep10 border-flowStep20 xl:rounded-lg">
        {/* Header section with select menu */}
        <div className="max-w-2xl px-4 pt-6 pb-8 mx-auto lg:max-w-screen-xl lg:flex lg:justify-between">
          <div className="max-w-xl">
            <h4 className="pb-2 mt-0 font-semibold">Try FlowStep for FREE</h4>
            <div className="sm:flex-none sm:mt-2 md:flex lg:flex-shrink-0 lg:mt-0">
              <div className="rounded-md shadow sm:block md:inline-flex">
                <Link href="/pricing">
                  <a className="items-center justify-center block px-4 py-2 text-sm font-medium leading-6 text-center transition duration-150 ease-in-out bg-white border border-transparent rounded-md md:text-left md:inline text-flowStep hover:bg-flowStep80 hover:text-white focus:outline-none focus:ring">
                    Free Plan
                    <svg
                      className="inline w-4 h-4 ml-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                      />
                    </svg>
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full mt-10 lg:max-w-lg lg:mt-0">
            <h4 className="pb-2 mt-0 font-semibold">
              Check out our other plans
            </h4>
            <div className="sm:flex-none sm:mt-2 md:flex lg:flex-shrink-0 lg:mt-0">
              <div className="rounded-md shadow sm:block md:inline-flex">
                <Link href="/pricing">
                  <a className="items-center justify-center block px-4 py-2 text-sm font-medium leading-6 text-center transition duration-150 ease-in-out bg-white border border-transparent rounded-md md:text-left md:inline text-flowStep hover:bg-flowStep80 hover:text-white focus:outline-none focus:ring">
                    Smaller Plans
                    <svg
                      className="inline w-4 h-4 ml-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                      />
                    </svg>
                  </a>
                </Link>
              </div>
              <div className="mt-5 rounded-md shadow sm:block md:mt-0 md:inline-flex md:ml-3">
                <Link href="/larger-plans">
                  <a className="items-center justify-center block px-4 py-2 text-sm font-medium leading-6 text-center text-white transition duration-150 ease-in-out border border-transparent rounded-md md:text-left md:inline bg-flowStep hover:text-white hover:bg-flowStep80 focus:outline-none focus:ring">
                    Larger Plans {/*  Heroicon name: office-building */}
                    &nbsp;
                    <svg
                      className="inline w-4 h-4 ml-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                      />
                    </svg>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FreeAndPlans
