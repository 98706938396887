import LandingPage from 'components/Home/landing'
import StaticLayout from 'layouts/StaticLayout'

import {
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
  NextPage,
} from 'next'
import nookies from 'nookies'
import { firebaseAdmin } from 'firebase-flowstep/firebaseAdmin'
import { QueryClient } from 'react-query'
import { dehydrate } from 'react-query/hydration'
import { getRedirect } from 'firebase-flowstep/getRedirect'
import UserService from 'services/user'

export const getServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  try {
    const cookies = nookies.get(context)
    const selectedRole = cookies.selectedRole
      ? JSON.parse(cookies.selectedRole)
      : null

    const token = await firebaseAdmin.auth().verifyIdToken(cookies.token)

    // the user is authenticated!
    const { email } = token

    const queryClient = new QueryClient()

    if (!selectedRole) {
      const flowStepUser =
        (await queryClient.fetchQuery([`GET USER`, email], () => {
          if (email) {
            return UserService.getUserByEmail(email, {
              headers: { Authorization: `Bearer ${cookies.token}` },
            })
          }
        })) ?? null

      if (flowStepUser) {
        return {
          redirect: {
            destination: '/role-selection',
            permanent: false,
          },
        }
      }
    } else if (selectedRole) {
      const flowStepUser =
        (await queryClient.fetchQuery([`GET USER`, email], () => {
          if (email) {
            return UserService.getUserByEmail(email, {
              headers: { Authorization: `Bearer ${cookies.token}` },
            })
          }
        })) ?? null

      const foundRole = flowStepUser?.roleRelations?.find((roleRelation) => {
        if (roleRelation.role === selectedRole.role) {
          return true
        }
      })

      if (!foundRole) {
        return {
          redirect: {
            destination: '/login',
            permanent: false,
          },
        }
      }

      const destination = getRedirect({ currentRole: selectedRole })

      return {
        redirect: {
          destination,
          permanent: false,
        },
      }
    }

    return {
      props: {
        dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
      },
    }
  } catch (err) {
    return { props: {} as never }
  }
}

type HomePageProps = InferGetServerSidePropsType<typeof getServerSideProps>

const HomePage: NextPage<HomePageProps> = () => {
  return (
    <StaticLayout>
      <LandingPage />
    </StaticLayout>
  )
}

export default HomePage
