import Link from 'next/link'
import React from 'react'

export interface Props {}

const FlowStepFor: React.FC<Props> = () => {
  return (
    <>
      {/* Leadership and Advisors */}
      <div className="p-px overflow-hidden divide-y rounded-lg shadow-sm bg-flowStep10 divide-flowStep110 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
        <div className="relative p-4 bg-white rounded-tl-lg rounded-tr-lg hover:bg-flowContext10 sm:rounded-tr-none group focus-within:ring-2 focus-within:ring-inset focus-within:ring-flowContext">
          <div className="mt-8">
            <h3 className="text-lg font-medium">
              <Link href="/about-flowstep/digital-business-transformation">
                <a className="flex items-center focus:outline-none">
                  <div className="mr-2">
                    <span className="inline-flex p-3 text-white rounded-lg bg-flowContext ring-4 ring-white">
                      {/* Heroicon name: outline/clock */}
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                  </div>
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  Digital Business &amp; Transformation
                </a>
              </Link>
            </h3>
            <p className="mt-2">
              Digital transformation is more than "doing agile" or "building
              microservices". Digital transformation is achieved by realising
              the potential of employees who are empowered and engaged.
            </p>
          </div>
          <span
            className="absolute pointer-events-none text-flowStep05 top-6 right-6 group-hover:text-flowContext"
            aria-hidden="true"
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        </div>
        <div className="relative p-4 bg-white hover:bg-flowAction10 sm:rounded-tr-lg group focus-within:ring-2 focus-within:ring-inset focus-within:ring-flowAction">
          <div className="mt-8">
            <h3 className="text-lg font-medium">
              <Link href="/about-flowstep/product-development-management">
                <a className="flex items-center focus:outline-none">
                  <div className="mr-2">
                    <span className="inline-flex p-3 text-white rounded-lg bg-flowAction ring-4 ring-white">
                      {/* Heroicon name: outline/clock */}
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
                        />
                      </svg>
                    </span>
                  </div>
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  Product Development &amp; Management
                </a>
              </Link>
            </h3>
            <p className="mt-2">
              No matter how viable you are making your product, FlowStep will
              help Product Owners define Product Roadmaps to create Product
              Backlogs for their Product Teams.
            </p>
          </div>
          <span
            className="absolute pointer-events-none text-flowStep05 top-6 right-6 group-hover:text-flowAction"
            aria-hidden="true"
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        </div>
        <div className="relative p-4 bg-white hover:bg-flowAPI10 group focus-within:ring-2 focus-within:ring-inset focus-within:ring-flowAPI">
          <div className="mt-8">
            <h3 className="text-lg font-medium">
              <Link href="/about-flowstep/experience-interface-design">
                <a className="flex items-center focus:outline-none">
                  <div className="mr-2">
                    <span className="inline-flex p-3 text-white rounded-lg bg-flowAPI ring-4 ring-white">
                      {/* Heroicon name: outline/clock */}
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                        />
                      </svg>
                    </span>
                  </div>
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  Experience &amp; Interface Design
                </a>
              </Link>
            </h3>
            <p className="mt-2">
              FlowStep supports your grand designs through the adoption design
              thinking philosophies and integrations with common design tools.
              {/* Design Thinking, Human Centred Design, User Centred Design,
                Service Design, Graphic Design, Usability Design. FlowStep
                supports you grand designs, no matter which methodology. */}
            </p>
          </div>
          <span
            className="absolute pointer-events-none text-flowStep05 top-6 right-6 group-hover:text-flowAPI"
            aria-hidden="true"
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        </div>
        <div className="relative p-4 bg-white hover:bg-flowOutcome10 group focus-within:ring-2 focus-within:ring-inset focus-within:ring-flowOutcome">
          <div className="mt-8">
            <h3 className="text-lg font-medium">
              <Link href="/about-flowstep/ui-backend-development">
                <a className="flex items-center focus:outline-none">
                  <div className="mr-2">
                    <span className="inline-flex p-3 text-white rounded-lg bg-flowOutcome ring-4 ring-white">
                      {/* Heroicon name: outline/clock */}
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                        />
                      </svg>
                    </span>
                  </div>
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  Software Development
                </a>
              </Link>
            </h3>
            <p className="mt-2">
              From the front to the back, we've got your back when it comes to
              defining, introducing, supporting or enforcing good practices.
            </p>
          </div>
          <span
            className="absolute pointer-events-none text-flowStep05 top-6 right-6 group-hover:text-flowOutcome"
            aria-hidden="true"
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        </div>
      </div>
    </>
  )
}

export default FlowStepFor
